
import ButtonComponent from '@/components/ButtonComponent.vue';
import DataIngestionButtonComponent from '@/components/DataIngestionButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { ModalMounter } from '@/components/ModalMounter';
import CreateApplicantModal from '@/components/Modals/Applicant/CreateApplicantModalComponent.vue';
import CreateBoardModal from '@/components/Modals/Board/CreateBoardModalComponent.vue';
import EditBoardModal from '@/components/Modals/Board/EditBoardModalComponent.vue';
import YesNoModal from '@/components/Modals/YesNoModalComponent.vue';
import SelectFileToUploadComponent from '@/components/SelectFileToUploadComponent.vue';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import { BoardDataProvider } from '@/components/Table/Board/BoardDataProvider';
import { boardTitleFilter } from '@/components/Table/Board/BoardFilters';
import { boardBoardTableConfigBuilder } from '@/components/Table/Board/BoardTableConfigBuilder';
import { BoardTableData } from '@/components/Table/Board/BoardTableData';
import { ApplicantStudentDataProvider } from '@/components/Table/Candidate/ApplicantStudent/ApplicantStudentDataProvider';
import { applicantStudentTableConfigBuilder } from '@/components/Table/Candidate/ApplicantStudent/ApplicantStudentTableConfigBuilder';
import { ApplicantStudentTableData } from '@/components/Table/Candidate/ApplicantStudent/ApplicantStudentTableData';
import {
  candidateEmailFilter,
  candidateFirstNameFilter,
  candidateIsArchivedFilter,
  candidateLastNameFilter
} from '@/components/Table/Candidate/CandidateFilters';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import { UserDataProvider } from '@/components/Table/User/UserDataProvider';
import {
  userDisplayNameFilter,
  userEmailFilter,
  userIsArchivedFilter,
  userRoleFilter
} from '@/components/Table/User/UserFilters';
import { userTableConfigBuilder } from '@/components/Table/User/UserTableConfigBuilder';
import { UserTableData } from '@/components/Table/User/UserTableData';
import { SearchBoardDto } from '@/models/Dtos/searchBoardDto';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { SearchUserDto } from '@/models/Dtos/searchUserDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Board } from '@/models/Entities/Board';
import { RecruitmentStatus } from '@/models/Entities/Personas/Constants';
import { User } from '@/models/Entities/User';
import { Component, Vue } from 'vue-property-decorator';
import { TableTypes } from './enums/TableTypes';
import { IButtonActionOptions } from './models/IButtonActionOptions';

@Component<AdminDashboardPage>({
  components: {
    DataIngestionButtonComponent,
    CreateApplicantModal,
    LegacyTableComponent,
    ButtonComponent,
    IconComponent,
    YesNoModal,
    EditBoardModal,
    CreateBoardModal,
    AsyncTableComponent,
    SelectFileToUploadComponent
  }
})
export default class AdminDashboardPage extends Vue {
  $refs!: {
    createApplicantModal: CreateApplicantModal;
    yesNoModal: YesNoModal;
    createBoardModal: CreateBoardModal;
    editBoardModal: EditBoardModal;
  };

  tableTabs: TableTypes[] = [
    TableTypes.BOARDS,
    TableTypes.USERS,
    TableTypes.APPLICANTS,
    TableTypes.STUDENTS
  ];

  activeTableTab: TableTypes = TableTypes.BOARDS;

  tabStyles =
    'px-2 pb-2 border-t-0 border-b-4 border-l-0 border-r-0 border-transparent rounded-none';
  inactiveTabStyles = this.tabStyles + ' border-none';

  mounted(): void {
    this.activeTableMap[TableTypes.BOARDS].queryData();
  }

  activeTableLocalStorageKey = {
    [TableTypes.BOARDS]: 'adminBoardTable',
    [TableTypes.USERS]: 'adminUserTable',
    [TableTypes.APPLICANTS]: 'adminApplicantTable',
    [TableTypes.STUDENTS]: 'adminStudentTable'
  };

  activeTableMap = {
    [TableTypes.BOARDS]: new TableSearchService<
      BoardTableData,
      Board,
      never,
      SearchBoardDto
    >(
      this.$store,
      new BoardDataProvider(this.$store),
      {
        includeArchived: true
      },
      { defaultSortProperty: 'title' },
      boardBoardTableConfigBuilder,
      { filters: [boardTitleFilter], quickFilters: [] }
    ),
    [TableTypes.USERS]: new TableSearchService<
      UserTableData,
      User,
      never,
      SearchUserDto
    >(
      this.$store,
      new UserDataProvider(this.$store),
      {
        includeArchived: true
      },
      { defaultSortProperty: 'displayName' },
      userTableConfigBuilder,
      {
        filters: [
          userDisplayNameFilter,
          userEmailFilter,
          userRoleFilter,
          userIsArchivedFilter
        ],
        quickFilters: []
      }
    ),
    [TableTypes.APPLICANTS]: new TableSearchService<
      ApplicantStudentTableData,
      BaseCandidate,
      never,
      SearchCandidateDto
    >(
      this.$store,
      new ApplicantStudentDataProvider(this.$store),
      {
        recruitmentStatus: RecruitmentStatus.APPLICANT,
        includeArchived: true
      },
      { defaultSortProperty: 'identificationInformationFirstname' },
      applicantStudentTableConfigBuilder,
      {
        filters: [
          candidateFirstNameFilter,
          candidateLastNameFilter,
          candidateEmailFilter,
          candidateIsArchivedFilter
        ],
        quickFilters: []
      }
    ),
    [TableTypes.STUDENTS]: new TableSearchService<
      ApplicantStudentTableData,
      BaseCandidate,
      never,
      SearchCandidateDto
    >(
      this.$store,
      new ApplicantStudentDataProvider(this.$store),
      {
        recruitmentStatus: RecruitmentStatus.STUDENT,
        includeArchived: true
      },
      { defaultSortProperty: 'identificationInformationFirstname' },
      applicantStudentTableConfigBuilder,
      {
        filters: [
          candidateFirstNameFilter,
          candidateLastNameFilter,
          candidateEmailFilter,
          candidateIsArchivedFilter
        ],
        quickFilters: []
      }
    )
  };

  activeDataIngestionHeaderButtons = {
    [TableTypes.BOARDS]: [],
    [TableTypes.USERS]: [],
    [TableTypes.APPLICANTS]: [
      {
        ingestionButtons: {
          text: 'Upload Applicants',
          color: 'val-button-blue',
          bg: 'white',
          showIcon: true,
          templateLocation: '/templates/csv/uploadApplicants.csv',
          fileTypes: ['csv'],
          fileExtensionErrorHandler: async (
            message: string,
            buttonOptions: IButtonActionOptions
          ): Promise<void> => {
            await buttonOptions.store?.dispatch(
              'snackBarModule/enqueue',
              message
            );
          },
          importData: async (
            files: File[],
            buttonOptions: IButtonActionOptions
          ): Promise<void> => {
            try {
              const result = await buttonOptions.store?.dispatch(
                'applicantModule/importApplicants',
                files[0]
              );
              const inserted = result.filter((item: any) => !!item);
              await buttonOptions.store?.dispatch('snackBarModule/enqueue', {
                message: `${inserted.length} of ${result.length} applicants added`
              });
            } catch (err) {
              await buttonOptions.store?.dispatch('snackBarModule/enqueue', {
                message: 'There was an error uploading the applicants',
                icon: 'x'
              });
            }
          }
        }
      }
    ],
    [TableTypes.STUDENTS]: []
  };

  activeHeaderButtons = {
    [TableTypes.BOARDS]: [
      {
        buttons: {
          label: 'New Board',
          clickHandler: async (
            buttonOptions: IButtonActionOptions
          ): Promise<void> => {
            try {
              const board =
                await ModalMounter.instance.refs.createBoardModal.open();
              await buttonOptions.store?.dispatch('boardModule/create', board);
            } catch (e) {
              if (e) {
                buttonOptions.store?.dispatch('snackBarModule/enqueue', {
                  message: 'Board failed to create, please try again',
                  icon: 'x'
                });
              }
            }
          }
        }
      }
    ],
    [TableTypes.USERS]: [],
    [TableTypes.APPLICANTS]: [
      {
        buttons: {
          label: 'Create Applicant',
          clickHandler: async (
            buttonOptions: IButtonActionOptions
          ): Promise<void> => {
            try {
              const goingToStayOnPage =
                await ModalMounter.instance.refs.createApplicantModal.open();
              if (goingToStayOnPage) {
                await buttonOptions.store?.dispatch('snackBarModule/enqueue', {
                  icon: 'checkGreen',
                  message: 'Applicant created'
                });
              }
            } catch (err: any) {
              if (err) {
                await buttonOptions.store?.dispatch('snackBarModule/enqueue', {
                  icon: 'caution',
                  message:
                    err.message || 'There was an error creating the applicant'
                });
              }
            }
          },
          iconName: 'person'
        }
      }
    ],
    [TableTypes.STUDENTS]: []
  };

  get searchService() {
    return this.activeTableMap[this.activeTableTab];
  }

  get emptyTableMessage(): string {
    return `0 ${this.activeTableTab}`;
  }

  get tableLocalStorageKey(): string {
    return this.activeTableLocalStorageKey[this.activeTableTab];
  }

  get headerButtons() {
    return this.activeHeaderButtons[this.activeTableTab];
  }

  get dataIngestionHeaderButtons() {
    return this.activeDataIngestionHeaderButtons[this.activeTableTab];
  }

  changeTable(tableType: TableTypes): void {
    this.activeTableTab = tableType;
    this.searchService.queryData();
  }

  handleButtonClick(
    clickHandler: (buttonOptions: IButtonActionOptions) => Promise<void>
  ): Promise<void> {
    const options = {
      store: this.$store,
      router: this.$router
    };
    return clickHandler(options);
  }
}
