import { ModalMounter } from '@/components/ModalMounter';
import DropdownTableElementComponent from '@/components/Table/Elements/DropdownTableElementComponent.vue';
import { EUserRoles } from '@/enums';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import { SearchUserDto } from '../../../models/Dtos/searchUserDto';
import { User } from '../../../models/Entities/User';
import { DropdownOptions } from '../../../models/Forms/FormOption';
import { ITableConfiguration } from '../../../models/Table/Table';
import { EntityActionButtons } from '../models/EntityActionButtons';
import { TableConfigBuilder } from '../models/TableConfigBuilder';
import TableSearchService from '../models/TableSearch.service';
import { DeleteUserActionBuilder } from './EntityActions/DeleteUserActionBuilder';
import { RestoreUserActionBuilder } from './EntityActions/RestoreUserActionBuilder';
import { UserTableData } from './UserTableData';

export const userTableConfigBuilder: TableConfigBuilder<
  UserTableData,
  User,
  never,
  SearchUserDto
> = (
  tableSearchService: TableSearchService<
    UserTableData,
    User,
    never,
    SearchUserDto
  >,
  store: Store<RootState>
): ITableConfiguration<UserTableData, User, never> => {
  const tableButtons = new EntityActionButtons<
    UserTableData,
    User,
    SearchUserDto
  >('staff', store, tableSearchService, ModalMounter.instance.refs.yesNoModal);
  return {
    columnConfigurations: [
      {
        columnTitle: 'Display Name',
        render: 'displayName',
        sortKey: 'displayName',
        defaultPinned: true
      },
      {
        columnTitle: 'Role',
        render: {
          componentRef: DropdownTableElementComponent,
          componentProps: (rowStaff: UserTableData) => {
            const values = Object.values(EUserRoles).map((role) => {
              return role === EUserRoles.UNIT_REPRESENTATIVE
                ? {
                    label: 'Unit Hiring PoC',
                    value: EUserRoles.UNIT_REPRESENTATIVE
                  }
                : { label: role, value: role };
            });
            return {
              values: values,
              dropDownValue: rowStaff.role,
              changeHandler: async (value: string | DropdownOptions) => {
                try {
                  rowStaff.role = value as EUserRoles;
                  const dto = User.toPatchDto(rowStaff);
                  dto.uid = rowStaff.id;
                  await store.dispatch('userModule/patchUser', dto);
                  await tableSearchService.queryData();
                } catch {
                  await store.dispatch('snackBarModule/enqueue', {
                    message: `There was an error updating the role.`,
                    icon: 'caution'
                  });
                }
              }
            };
          }
        },
        sortKey: 'role'
      },
      {
        columnTitle: 'Email',
        render: 'email',
        sortKey: 'email'
      },
      tableButtons.buildEntityActionsColumn([
        new DeleteUserActionBuilder(),
        new RestoreUserActionBuilder()
      ])
    ]
  };
};
