
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from './IconComponent.vue';

@Component<SelectFileToUploadComponent>({
  components: {
    ButtonComponent,
    IconComponent
  }
})
export default class SelectFileToUploadComponent extends Vue {
  @Prop()
  text!: string;

  @Prop({ default: 'val-button-blue' })
  bg?: string;

  @Prop({ default: 'white' })
  color?: string;

  @Prop({ default: false })
  showIcon?: boolean;

  @Prop()
  fileTypes?: string[];

  $refs!: {
    fileUpload: HTMLInputElement;
  };

  private async updateValue(data: FileList) {
    if (this.fileTypes && data[0]) {
      const fileExtension = data[0].name.split('.').pop();
      const validExtension = this.fileTypes
        .map((type) => type.toLowerCase())
        .includes(String(fileExtension).toLowerCase());
      if (!validExtension) {
        this.$emit('fileExtensionError');
        return;
      }
    }
    this.$emit('importData', data);
  }

  public chooseFile(): void {
    const ref = this.$refs['fileUpload'];
    ref.value = '';
    ref.click();
  }
}
