import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { RestoreEntityActionBuilder } from '../../EntityActions/RestoreEntityAction';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { IBaseCandidateTableData } from '../BaseCandidateTableData';

export class RestoreCandidateActionBuilder<
  TableDataType extends IBaseCandidateTableData
> extends RestoreEntityActionBuilder<
  TableDataType,
  BaseCandidate,
  SearchCandidateDto
> {
  protected restore = async (
    baseCandidateTableData: IBaseCandidateTableData,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      BaseCandidate,
      SearchCandidateDto
    >
  ): Promise<void> => {
    return await entityActionButtons.store.dispatch(
      'candidateModule/restore',
      baseCandidateTableData.entityId
    );
  };
}
