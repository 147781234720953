import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { ITableData } from '../../models/TableData';

export class ApplicantStudentTableData implements ITableData {
  entityId: number;
  id: number;
  managerId: number | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isArchived: boolean;
  socialSecurityNumber: string | null;

  constructor(candidate: BaseCandidate) {
    this.entityId = candidate.id;
    this.firstName = candidate.identificationInformationFirstname;
    this.lastName = candidate.identificationInformationLastname;
    this.email = candidate.contactInformationEmail;
    this.isArchived = candidate.isArchived;
    this.id = candidate.id;
    this.managerId = candidate.managerId;
    this.socialSecurityNumber =
      candidate.identificationInformationSocialsecuritynumber;
  }
}
