var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "p-4 bg-gray-100" }, [
    _c("h3", { staticClass: "my-4 ml-4" }, [_vm._v("Admin")]),
    _c("div", { staticClass: "bg-white border rounded" }, [
      _c(
        "div",
        { staticClass: "p-4 pb-0 border-b" },
        _vm._l(_vm.tableTabs, function (tableTab, index) {
          return _c(
            "button-component",
            {
              key: index,
              class:
                _vm.activeTableTab == tableTab
                  ? _vm.tabStyles
                  : _vm.inactiveTabStyles,
              attrs: { "active-class": "border-blue-500" },
              on: {
                click: function ($event) {
                  return _vm.changeTable(tableTab)
                },
              },
            },
            [_vm._v(" " + _vm._s(tableTab) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "w-full p-4 bg-white" },
        [
          _c(
            "async-table-component",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    isSortable: true,
                    isColumnEditable: true,
                    isFilterable: true,
                    localStorageKey: _vm.tableLocalStorageKey,
                    emptyTableMessage: _vm.emptyTableMessage,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "settings-right",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex items-center" },
                            [
                              _vm._l(_vm.headerButtons, function (button) {
                                return _c(
                                  "button-component",
                                  {
                                    key: button.buttons.label,
                                    staticClass: "mr-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleButtonClick(
                                          button.buttons.clickHandler
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        button.buttons.iconName
                                          ? _c("icon-component", {
                                              staticClass: "mr-1",
                                              attrs: {
                                                name: button.buttons.iconName,
                                                fill: "blue",
                                                width: 13,
                                                height: 14,
                                              },
                                            })
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(_vm._s(button.buttons.label)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              _vm._l(
                                _vm.dataIngestionHeaderButtons,
                                function (button) {
                                  return _c("data-ingestion-button-component", {
                                    key: button.ingestionButtons.text,
                                    attrs: {
                                      showIcon:
                                        button.ingestionButtons.showIcon,
                                      text: button.ingestionButtons.text,
                                      color: button.ingestionButtons.color,
                                      bg: button.ingestionButtons.bg,
                                      templateLocation:
                                        button.ingestionButtons
                                          .templateLocation,
                                      fileTypes:
                                        button.ingestionButtons.fileTypes,
                                    },
                                    on: {
                                      fileExtensionError:
                                        button.ingestionButtons
                                          .fileExtensionErrorHandler,
                                      importData:
                                        button.ingestionButtons.importData,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                "async-table-component",
                _vm.searchService.tableProps,
                false
              ),
              _vm.searchService.tableEventListeners
            )
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }