import { EUserRoles } from '@/enums';
import { User } from '@/models/Entities/User';
import { ITableData } from '../models/TableData';

export class UserTableData implements ITableData {
  entityId: number | string | null;
  id: string | null;
  role: EUserRoles | null;
  email: string | null;
  displayName: string | null;
  isArchived: boolean;

  constructor(user: User) {
    this.entityId = user.uid;
    this.id = user.uid;
    this.role = user.role;
    this.email = user.email;
    this.displayName = user.displayName;
    this.isArchived = user.isArchived;
  }
}
