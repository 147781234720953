import { UserTableData } from './UserTableData';
import { TableDataProvider } from '../models/TableDataProvider';
import { User } from '@/models/Entities/User';
import { SearchUserDto } from '@/models/Dtos/searchUserDto';
import { SearchResponseDto } from '../../../models/Dtos/common/searchResultDto';

export class UserDataProvider extends TableDataProvider<
  UserTableData,
  User,
  SearchUserDto
> {
  protected defaultSortProperty: keyof UserTableData = 'id';
  protected tableDataEntityKeyMap: Record<keyof UserTableData, keyof User> = {
    id: 'uid',
    email: 'email',
    displayName: 'displayName',
    isArchived: 'isArchived',
    role: 'role',
    entityId: 'uid'
  };
  protected transformer = (t: User): UserTableData => new UserTableData(t);

  protected queryHandler = async (
    searchDto: SearchUserDto
  ): Promise<SearchResponseDto<User>> => {
    const results: SearchResponseDto<User> = await this.store.dispatch(
      'userModule/fetchWithSelectParameters',
      searchDto
    );
    return results;
  };
}
