var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex items-center" },
    [
      _c("select-file-to-upload-component", {
        attrs: {
          bg: _vm.bg,
          color: _vm.color,
          text: _vm.text,
          showIcon: _vm.showIcon,
          fileTypes: _vm.fileTypes,
        },
        on: {
          fileExtensionError: _vm.handleError,
          importData: _vm.updateValue,
        },
      }),
      _vm.templateLocation
        ? _c(
            "a",
            {
              staticClass:
                "flex items-center h-8 px-4 py-1 text-sm text-blue-500 border border-blue-500 rounded focus:outline-none whitespace-nowrap",
              attrs: { href: _vm.templateLocation },
            },
            [
              _c("icon-component", {
                staticClass: "fill-current",
                attrs: { name: "download", height: 18, width: 18 },
              }),
              _c("span", { staticClass: "pl-2" }, [
                _vm._v("Download Template"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }