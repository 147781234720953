var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("input", {
        ref: "fileUpload",
        staticClass: "hidden",
        attrs: { "data-cy": "fileUploadInput", type: "file", name: "" },
        on: {
          change: function ($event) {
            return _vm.updateValue($event.target.files)
          },
        },
      }),
      _c(
        "button-component",
        {
          staticClass: "ml-2 mr-4",
          attrs: { textOrBorderColor: _vm.color, bg: _vm.bg },
          on: { click: _vm.chooseFile },
        },
        [
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _vm.showIcon
                ? _c("icon-component", {
                    staticClass: "mr-2 fill-current",
                    attrs: { name: "upload", height: 20, width: 20 },
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.text) + " "),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }