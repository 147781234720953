import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { ApplicantStudentTableData } from './ApplicantStudentTableData';
import { TableDataProvider } from '../../models/TableDataProvider';

export class ApplicantStudentDataProvider extends TableDataProvider<
  ApplicantStudentTableData,
  BaseCandidate,
  SearchCandidateDto
> {
  protected transformer = (t: BaseCandidate): ApplicantStudentTableData =>
    new ApplicantStudentTableData(t);

  protected queryHandler = async (
    searchDto: SearchCandidateDto
  ): Promise<SearchResponseDto<BaseCandidate>> => {
    const results: SearchResponseDto<BaseCandidate> = await this.store.dispatch(
      'studentModule/fetchWithSelectParameters',
      searchDto
    );
    return results;
  };
}
