
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from './IconComponent.vue';
import SelectFileToUploadComponent from './SelectFileToUploadComponent.vue';

@Component<DataIngestionButtonComponent>({
  components: {
    IconComponent,
    SelectFileToUploadComponent
  }
})
export default class DataIngestionButtonComponent extends Vue {
  @Prop({ default: '' })
  text!: string;

  @Prop({ default: 'blue-500' })
  bg?: string;

  @Prop({ default: 'white' })
  color?: string;

  @Prop({ default: false })
  showIcon?: boolean;

  @Prop()
  templateLocation?: string;

  @Prop()
  fileTypes?: string[];

  $refs!: {
    fileUpload: HTMLInputElement;
  };

  private updateValue(fileList: FileList) {
    this.$emit('importData', fileList);
  }

  private handleError() {
    this.$emit('fileExtensionError');
  }
}
