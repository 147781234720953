import { ModalMounter } from '@/components/ModalMounter';
import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import ApplicantSelectRecruiterDropDown from '../../Elements/ApplicantSelectRecruiterDropDown.vue';
import LinkElementComponent from '../../Elements/LinkElementComponent.vue';
import ShowSocialSecurityComponent from '../../Elements/ShowSocialSecurityComponent.vue';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import TableSearchService from '../../models/TableSearch.service';
import { DeleteCandidateActionBuilder } from '../EntityActions/DeleteCandidateActionBuilder';
import { RestoreCandidateActionBuilder } from '../EntityActions/RestoreCandidateActionBuilder';
import { ApplicantStudentTableData } from './ApplicantStudentTableData';

export const applicantStudentTableConfigBuilder: TableConfigBuilder<
  ApplicantStudentTableData,
  BaseCandidate,
  never,
  SearchCandidateDto
> = (
  tableSearchService: TableSearchService<
    ApplicantStudentTableData,
    BaseCandidate,
    never,
    SearchCandidateDto
  >,
  store: Store<RootState>
): ITableConfiguration<ApplicantStudentTableData, BaseCandidate, never> => {
  const tableButtons = new EntityActionButtons<
    ApplicantStudentTableData,
    BaseCandidate,
    SearchCandidateDto
  >('person', store, tableSearchService, ModalMounter.instance.refs.yesNoModal);
  return {
    columnConfigurations: [
      {
        columnTitle: 'First Name',
        render: 'firstName',
        sortKey: 'identificationInformationFirstname',
        defaultPinned: true
      },
      {
        columnTitle: 'Last Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowCandidate: ApplicantStudentTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowCandidate.lastName,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowCandidate.id }
              }
            };
          }
        },
        sortKey: 'identificationInformationLastname'
      },
      {
        columnTitle: 'Is Archived',
        render: 'isArchived',
        sortKey: 'deletedAt'
      },
      {
        columnTitle: 'Social Security Number',
        render: {
          componentRef: ShowSocialSecurityComponent,
          componentProps: (
            rowCandidate: ApplicantStudentTableData
          ): Record<string, unknown> => {
            return {
              ssnString: rowCandidate.socialSecurityNumber
            };
          }
        },
        sortKey: 'identificationInformationSocialsecuritynumber'
      },
      {
        columnTitle: 'Recruiter',
        render: {
          componentRef: ApplicantSelectRecruiterDropDown,
          componentProps: (
            rowCandidate: ApplicantStudentTableData
          ): Record<string, unknown> => {
            return {
              applicant: rowCandidate,
              updateDelegate:
                tableSearchService.queryData.bind(tableSearchService)
            };
          }
        }
        // sortKey: 'manager' is a relation so doesn't sort properly- this is a NOT DO
      },
      tableButtons.buildEntityActionsColumn([
        new DeleteCandidateActionBuilder(),
        new RestoreCandidateActionBuilder()
      ])
    ]
  };
};
