import { SearchUserDto } from '../../../models/Dtos/searchUserDto';
import {
  booleanFilterComponentDataBuilder,
  stringFilterComponentDataBuilder
} from '@/models/Table/Table';
import { EUserRoles } from '../../../enums';

/**
 * Filters go into the filter dropdown and usually have inputs to define the filter value
 */
export const userDisplayNameFilter = stringFilterComponentDataBuilder(
  'Display Name',
  (dto: SearchUserDto, value: string) => (dto.displayName = value)
);

export const userEmailFilter = stringFilterComponentDataBuilder(
  'Email',
  (dto: SearchUserDto, value: string) => (dto.email = value)
);

export const userRoleFilter = stringFilterComponentDataBuilder(
  'Role',
  (dto: SearchUserDto, value: string) => (dto.role = value as EUserRoles)
);

export const userIsArchivedFilter = booleanFilterComponentDataBuilder(
  'Is Archived',
  (dto: SearchUserDto, value: boolean) => (dto.isArchived = value)
);
