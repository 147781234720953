import { SearchUserDto } from '@/models/Dtos/searchUserDto';
import { User } from '@/models/Entities/User';
import { RestoreEntityActionBuilder } from '../../EntityActions/RestoreEntityAction';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { IBaseUserTableData } from '../BaseUserTableData';

export class RestoreUserActionBuilder<
  TableDataType extends IBaseUserTableData
> extends RestoreEntityActionBuilder<TableDataType, User, SearchUserDto> {
  protected restore = async (
    baseUserTableData: IBaseUserTableData,
    entityActionButtons: EntityActionButtons<TableDataType, User, SearchUserDto>
  ): Promise<void> => {
    return await entityActionButtons.store.dispatch(
      'userModule/restoreUser',
      baseUserTableData.entityId
    );
  };
}
