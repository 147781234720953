import { SearchUserDto } from '@/models/Dtos/searchUserDto';
import { User } from '@/models/Entities/User';
import { DeleteEntityActionBuilder } from '../../EntityActions/DeleteEntityAction';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { IBaseUserTableData } from '../BaseUserTableData';

export class DeleteUserActionBuilder<
  TableDataType extends IBaseUserTableData
> extends DeleteEntityActionBuilder<TableDataType, User, SearchUserDto> {
  public deleteString: 'delete' | 'archive' = 'archive';
  protected delete = async (
    baseUserTableData: IBaseUserTableData,
    entityActionButtons: EntityActionButtons<TableDataType, User, SearchUserDto>
  ): Promise<void> => {
    return entityActionButtons.store.dispatch(
      'userModule/deleteUser',
      baseUserTableData.entityId
    );
  };
}
