import { SearchCandidateDto } from '@/models/Dtos/searchCandidateDto';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { DeleteEntityActionBuilder } from '../../EntityActions/DeleteEntityAction';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { IBaseCandidateTableData } from '../BaseCandidateTableData';

export class DeleteCandidateActionBuilder<
  TableDataType extends IBaseCandidateTableData
> extends DeleteEntityActionBuilder<
  TableDataType,
  BaseCandidate,
  SearchCandidateDto
> {
  public deleteString: 'delete' | 'archive' = 'archive';
  protected delete = async (
    baseCandidateTableData: IBaseCandidateTableData,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      BaseCandidate,
      SearchCandidateDto
    >
  ): Promise<void> => {
    return entityActionButtons.store.dispatch(
      'candidateModule/delete',
      baseCandidateTableData.entityId
    );
  };
}
